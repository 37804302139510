import * as React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import Image from "gatsby-image"
import BlockContent from "@sanity/block-content-to-react"

import { CONTENT_SIZE } from "style/size"

import { Layout } from "components/Layout"

import { Title } from "components/text/Title"
import { ContentPortableText } from "components/blockContent/ContentPortableText"
import { Text } from "components/text/Text"
import { TitleSmall } from "components/text/TitleSmall"
import { Bold } from "components/text/Bold"
import { COLOR_BLACK } from "../style/colors"

/*const Content = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow: auto;
`*/

const Employees = styled.div`
	padding: 0 24px;
	margin: 0 auto;
	margin-bottom: ${props => (props.noMargin ? "0" : "40px")};
	max-width: ${CONTENT_SIZE}px;
	width: 100%;

	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
	grid-gap: 40px;
	${Text} {
		font-weight: 300;
		font-size: 14px;
		line-height: 16px;
	}
`

const Employee = styled.div``

const Name = styled.h2`
	font-family: Inter;
	font-style: normal;
	font-weight: bold;
	font-size: 20px;
	line-height: 36px;
	text-transform: uppercase;
	color: ${COLOR_BLACK};
	border-bottom: 4px solid #00587c;
`
const Jobtitle = styled(Text)`
	text-transform: uppercase;
`

const BlockRenderer = props => {
	const { style = "normal" } = props.node

	if (style === "normal") {
		return <Text>{props.children}</Text>
	}

	if (style === "h2") {
		return <TitleSmall as="h2">{props.children}</TitleSmall>
	}

	if (style === "h3") {
		return (
			<Text as="h3">
				<Bold>{props.children}</Bold>
			</Text>
		)
	}

	if (style === "blockquote") {
		return <Text>- {props.children}</Text>
	}
	// Fall back to default handling
	return BlockContent.defaultSerializers.types.block(props)
}

/**
 * Teams needs to be a template since the slug is dynamic
 * @param {*} param0
 * @returns
 */
const TemplateTeam = ({ data: { sanityTeam } }) => {
	console.log({ sanityTeam })

	const { title, meta, employees = [] } = sanityTeam || {}

	return (
		<Layout meta={meta}>
			<Title>{title}</Title>
			<Employees>
				{employees.map(employee => {
					const {
						_key,
						title: jobtitle,
						name,
						phone,
						email,
						_rawBio,
						image,
					} = employee || {}
					const { alt } = image || {}
					const { fluid } = image?.image?.asset || {}
					if (!name) return null
					return (
						<Employee key={_key}>
							{fluid && <Image fluid={fluid} alt={alt} />}
							<Name>{name}</Name>
							<Jobtitle>{jobtitle}</Jobtitle>
							<ContentPortableText
								blocks={_rawBio}
								serializers={{
									type: {
										block: BlockRenderer,
									},
								}}
							/>
							<Text>
								<Bold>Epost:</Bold> {email}
							</Text>
							<Text>
								<Bold>Tel:</Bold> {phone}
							</Text>
						</Employee>
					)
				})}
			</Employees>
		</Layout>
	)
}

export const query = graphql`
	query GetTeamPageElements {
		sanityTeam {
			title
			slug {
				current
			}
			meta {
				title
				description
			}
			employees {
				_key
				title
				name
				phone
				email
				_rawBio(resolveReferences: { maxDepth: 10 })
				image {
					alt
					image {
						asset {
							fluid {
								srcWebp
								srcSetWebp
								srcSet
								src
								sizes
								base64
								aspectRatio
							}
						}
					}
				}
			}
		}
	}
`

export default TemplateTeam
